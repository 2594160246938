import { isEmpty } from "ramda";

const getEnv = <T,>(runEnv: T, buildEnv: T | undefined, defaultEnv: T): T => {
  if (import.meta.env.MODE === "development") {
    return buildEnv || defaultEnv;
  } else {
    return !isEmpty(runEnv) ? (runEnv as T) : (buildEnv ?? defaultEnv);
  }
};
export const API_ROOT = getEnv<string>(import.meta.env.SINCH_API_ROOT, import.meta.env.SINCH_API_ROOT, "/");

// @ts-ignore injected in run
export const ERROR_MONITOR_API_KEY = getEnv<string | null>(
  "$SINCH_FRONTEND_ERROR_MONITOR_API_KEY",
  import.meta.env.SINCH_ERROR_MONITOR_API_KEY,
  null
);

export const ERROR_MONITOR_DEBUG = getEnv<boolean>(
  // @ts-expect-error injected in run
  "$SINCH_ERROR_MONITOR_DEBUG" === "true",
  import.meta.env.SINCH_ERROR_MONITOR_DEBUG === "true",
  false
);

export const ERROR_MONITOR_ENV = getEnv<string>("$SINCH_ENVIRONMENT", import.meta.env.SINCH_ENVIRONMENT, "production");

export const GOOGLE_MAPS_API_KEY = getEnv<string | null>(
  "$SINCH_GOOGLE_MAPS_API_KEY",
  import.meta.env.SINCH_GOOGLE_MAPS_API_KEY,
  null
);
